.mat-mdc-text-field-wrapper.mdc-text-field {
  border-radius: 0.25rem;
  border: 1px solid rgb(0 0 0 / 0.1);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}

.mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field {
  width: 100%;
  font-family: Arial, sans-serif !important;
}

[type="text"]:focus,
input:where(:not([type])):focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-inset: none;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
  border-color: red; // Or the color you want
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field.mat-focused .mat-form-field-wrapper .mat-form-field-flex {
  outline: 2px solid #e53238 !important;
}

.mat-form-field-infix {
  padding: 0 !important;
  border-top: 0 !important;
  position: unset !important;
}

.mat-form-field-infix input {
  line-height: 2 !important;
  font-size: 0.875rem !important;
}

.mat-calendar-body-cell-content {
  border-radius: 0.25rem !important;
}

.mat-calendar-body-selected {
  background-color: #e53238;
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: transparent !important;
    border: 1px solid #e53238 !important;
  }
}

.mat-datepicker-toggle-active * svg {
  color: #e53238 !important;
}

.mat-form-field-invalid {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      outline: 2px solid #e53238 !important;
    }
  }
}
