.app-loading {
  opacity: 0.5;
  pointer-events: none;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices fix for position fixed not 100vh */
  .ios-fix.fixed {
    position: sticky;
  }
}

#cm.cloud {
  max-width: 1216px;
}

#cc--main {
  --cc-btn-primary-bg: #e53238;
  --cc-btn-primary-text: #fff;
  --cc-btn-primary-hover-bg: rgb(51, 51, 51);
  --cc-btn-secondary-bg: #fff;
  --cc-btn-secondary-text: #fff;
  --cc-btn-secondary-hover-bg: #000;
}

#c-ttl {
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: #000000;
}

#c-txt {
  font-family: Arial, sans-serif;
  font-size: 14px !important;
  color: #000000;
}

#c-txt a {
  color: #000000;
  border-bottom: none;

  &:hover {
    color: #e53238;
  }
}
