.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #757575 transparent;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  width: 6px;
  border-radius: 100vh;
  background: #333333;
  border: 1px solid transparent;
  background-clip: padding-box;
}
