@tailwind base;
@tailwind components;
@tailwind utilities;

.hyphens-auto {
  hyphens: auto;
}

.prose :where(h2):not(:where([class~="not-prose"] *)),
.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: theme("colors.primary");
}

.prose * a {
  color: theme("colors.primary") !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: #000000;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select,
[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ios-fixed {
  transform: translate3d(0, 0, 0);
}
